export const headers = [
    {
        text: 'Cliente',
        sortable: false,
        value: 'name',
        width: 320,
        align: 'left',
    },
    {
        text: 'CPF/CNPJ',
        sortable: false,
        value: 'document',
        align: 'center',
    },
    {
        text: 'Status',
        sortable: false,
        value: 'status',
        align: 'center',
    },
    {
        text: 'Criado',
        sortable: false,
        value: 'createdAt',
        align: 'center',
    },
    {
        text: 'Atualizado',
        sortable: false,
        value: 'updatedAt',
        align: 'center',
    },
];
