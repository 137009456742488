<template>
    <v-main flat v-if="limit">
        <div class="pt-6 pb-6 pr-6 pl-6 shadowTopDet">
            <v-row class="mb-3">
                <v-col md="9" sm="4" align-self="center">
                    <v-icon class="icon-account mr-3 icon-card-title">mdi-folder-outline</v-icon>
                    <span class="pt-5 pb-5 title-name">{{ limit.name }}</span>
                </v-col>
            </v-row>
        </div>
        <div class="pb-2 pr-9 pl-9 shadowBotDet">
            <v-row>
                <v-col md="9" sm="4" align-self="center">
                    <v-icon class="mr-1 icon-card icon-card-title">mdi-credit-card-outline</v-icon>
                    <span class="pt-5 pb-5 limite-title">Utilização do Limite</span>
                </v-col>
                <v-col md="3" sm="3" class="mt-3" align-self="center" align="right">
                    <img class="widgetImg2" height="38" :src="imgUrl" />
                </v-col>
            </v-row>
            <v-row>
                <v-col lg="12" xl="12" cols="12" class="mt-0 pb-0 pt-0">
                    <div id="wrapper">
                        <div id="chart-area">
                            <apexchart
                                height="350px"
                                :series="[limit.percentage]"
                                :options="options"
                            ></apexchart>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row justify="center" class="mt-0">
                <span class="label-valor font-weight-light">Valor disponível</span>
            </v-row>
            <v-row justify="center">
                <sup class="symbol-value">R$</sup>
                <span class="text-value">{{ formatWithoutSymbol(limit.availableAmount) }}</span>
            </v-row>
            <v-row justify="center">
                <v-col md="8">
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row justify="center" class="pb-6">
                <v-col md="4" sm="12" class="pa-0 date-label" align="center">
                    <v-icon class="mr-1 mb-1 icon-card">mdi-calendar-check-outline</v-icon>
                    <span>Data Decisão: </span>
                    <span class="font-weight-light">{{ formatarData(limit.decisionDate) }}</span>
                </v-col>
                <v-col md="4" sm="12" class="pa-0 date-label" align="center">
                    <v-icon class="mr-1 mb-1 icon-card">mdi-calendar-check-outline</v-icon>
                    <span>Validade Decisão: </span>
                    <span class="font-weight-light">{{ formatarData(limit.validityDate) }}</span>
                </v-col>
            </v-row>

            <v-row class="pb-6">
                <v-col class="mt-2" lg="4" sm="12">
                    <CardDetail
                        :amount="formatWithoutSymbol(limit.value)"
                        :date="formatarData(limit.updatedAt)"
                        :title="'Aprovado'"
                    />
                </v-col>

                <v-col class="mt-2" lg="4" sm="12">
                    <CardDetail
                        :amount="formatWithoutSymbol(limit.inUseAmount)"
                        :date="formatarData(limit.updatedAt)"
                        :title="'Utilizado'"
                    />
                </v-col>

                <v-col class="mt-2" lg="4" sm="12">
                    <CardDetail
                        :amount="formatWithoutSymbol(limit.movementAmount)"
                        :date="formatarData(limit.updatedAt)"
                        :title="'Movimento'"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="9" class="mb-0 pb-0" align-self="center">
                    <v-icon class="mr-3 icon-card icon-card-title">mdi-book-open-outline</v-icon>
                    <span class="title-icon">Dados</span>
                </v-col>
                <v-col cols="12">
                    <v-divider></v-divider>
                </v-col>
            </v-row>

            <div>
                <v-row class="ml-2 mt-5">
                    <div class="list-data">
                        <span class="font-weight-bold">Localidade: </span>
                        <span class="font-weight-light"
                            >{{ limit.location }} - {{ limit.city }}/{{ limit.state }}</span
                        >
                    </div>
                </v-row>
            </div>

            <div>
                <v-row class="mt-5">
                    <v-col cols="9" class="mb-0 pb-0" align-self="center">
                        <v-icon class="mr-3 icon-card icon-card-title"
                            >mdi-book-open-outline</v-icon
                        >
                        <span class="pb-5 title-icon">Clientes do Grupo</span>
                    </v-col>
                    <v-col cols="12"><v-divider></v-divider> </v-col>
                </v-row>
                <div class="pt-10 pb-6">
                    <v-data-table
                        :headers="headers"
                        :items="limit.registers"
                        hide-default-footer
                        class="elevation-1 pb-4"
                        id="table-meus-clients-consulta"
                    >
                        <template v-slot:[`item.status`]="{ item }">
                            <v-chip small :class="`status-chip ${getCredito(item.status).color}`">
                                {{ getCredito(item.status).label }}
                            </v-chip>
                        </template>
                        <template slot="no-data">
                            <div>Não há dados</div>
                        </template>
                    </v-data-table>
                </div>
            </div>
        </div>
    </v-main>
    <Loader align="center" justify="center" class="text-center mt-12" v-else />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getImgUrl } from '@/helpers/images';
import { CreditStatusColors, RequestStatusEnum } from '@/helpers/consts';
import { notification } from '@/helpers/notification';
import { headers } from './_headersDetalheGrupo';
import chartOptions from './_chartOptions';
import CardDetail from '@/mfes/credito/components/_cardDetail';
import { formatarData } from '@/helpers/data';
import { formatWithoutSymbol } from '@/helpers/formatarDinheiro';
import Loader from '@/components/base/Loader';

export default {
    components: {
        CardDetail,
        Loader,
    },
    data: () => ({
        headers,
        options: chartOptions,
        RequestStatusEnum,
        page: 1,
    }),
    computed: {
        ...mapGetters('credito', {
            limit: 'limit',
            limitRequestStatus: 'limitRequestStatus',
            selectedProduct: 'selectedProduct',
        }),
        id() {
            return this.$router.history.current.params.id;
        },
        imgUrl() {
            return this.limit && this.limit.img ? getImgUrl(this.limit.img) : '';
        },
    },
    methods: {
        ...mapActions('credito', {
            getLimit: 'getLimit',
            resetLimit: 'resetLimit',
        }),
        getCredito(key) {
            return CreditStatusColors[parseInt(key, 10)];
        },
        formatarData,
        formatWithoutSymbol,
    },

    async created() {
        this.resetLimit();
        this.getLimit({ id_produto: this.selectedProduct.id, id: this.id });
    },
    watch: {
        limitRequestStatus(newValue) {
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    'Ocorreu um erro ao requisitar o limite: ' + newValue.message
                );
            }
            return newValue;
        },
    },
};
</script>

<style scoped lang="scss">
@import './Consulta.scss';
.icon-card-title.v-icon {
    vertical-align: text-bottom;
}
</style>
